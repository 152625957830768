import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Container, Grid, Card, CircularProgress, Tabs, Tab, Box } from '@mui/material';
import axios from 'axios';
import TabPanel from './TabPanel';
import '../../App.css';
import logo from '../../logo_imorgante-removebg.png';
const ShowMenu = () => {
  const [locali, setLocali] = useState([]);
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [menuCategories, setMenuCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSubTab, setSelectedSubTab] = useState(0);
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get('https://imorgantebackend.kpsfactory.datadomus.com/public/api/getRecords/locale')
      .then(response => {
        const localiData = response.data.data;
        setLocali(localiData);
        if (localiData.length > 0) {
          loadMenu(localiData[0].id);
        }
      })
      .catch(error => console.error('Errore caricamento locali:', error));
  }, []);

  const loadMenu = (localeId) => {
    setLoading(true);
    axios.get(`https://imorgantebackend.kpsfactory.datadomus.com/public/api/menu/${localeId}`)
      .then(response => {
        setMenuCategories(response.data);
        setSelectedLocale(localeId);
        setSelectedTab(0);
        const firstCategory = response.data[0];
        if (firstCategory.subcategories.length > 0) {
          setSelectedSubTab(0);
          setDishes(firstCategory.subcategories[0]?.dishes || []);
        } else {
          setDishes(firstCategory.dishes || []);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Errore caricamento menu:', error);
        setLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const selectedCategory = menuCategories[newValue];
    if (selectedCategory?.subcategories?.length > 0) {
      setSelectedSubTab(0);
      setDishes(selectedCategory.subcategories[0]?.dishes || []);
    } else {
      setSelectedSubTab(null);
      setDishes(selectedCategory?.dishes || []);
    }
  };

  const handleSubTabChange = (event, newValue) => {
    setSelectedSubTab(newValue);
    const selectedCategory = menuCategories[selectedTab];
    const selectedSubcategory = selectedCategory?.subcategories[newValue];
    setDishes(selectedSubcategory?.dishes || []);
  };

  return (
    <Container>
      <AppBar position="static" style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
        <Toolbar style={{ justifyContent: 'center' }}>
          <img
            src={logo}
            alt="Logo"
            style={{ height: 80 }}
          />
        </Toolbar>
      </AppBar>

      {selectedLocale && (
        <Box mt={4}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Categorie menu"
            variant="scrollable"
            scrollButtons="on"  // Forza la visualizzazione delle frecce
            allowScrollButtonsMobile={true} // Abilita le frecce anche su dispositivi mobili
          >
            {menuCategories.map((category, index) => (
              <Tab label={category.category.title} key={index} />
            ))}
          </Tabs>

          {menuCategories[selectedTab]?.subcategories?.length > 0 && (
            <Tabs
              value={selectedSubTab}
              onChange={handleSubTabChange}
              aria-label="Sottocategorie menu"
              variant="scrollable"
              scrollButtons="on"
              allowScrollButtonsMobile={true} // Abilita le frecce anche su dispositivi mobili
              style={{ marginTop: '20px' }}
            >
              {menuCategories[selectedTab].subcategories.map((subcategory, index) => (
                <Tab label={subcategory.subcategory.title} key={index} />
              ))}
            </Tabs>
          )}

          {loading ? (
            <Box display="flex" justifyContent="center" mt={3}>
              <CircularProgress />
            </Box>
          ) : (
            <TabPanel value={selectedTab} index={selectedTab}>
              <Grid container spacing={3}>
                {dishes.map((dish) => (
                  <Grid item xs={12} sm={4} key={dish.id}>
                    <Card style={{ padding: '20px', textAlign: 'center', backgroundColor: '#fff' }}>
                      <img
                        className="product-image"
                        src={dish.photos[0]}
                        alt={dish.title}
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          margin: '0 auto 10px',
                        }}
                      />
                      <div className="product-info">
                        <div className="product-title" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                          {dish.title}
                        </div>
                        <p className="product-description" style={{ fontSize: '14px', color: '#555', marginTop: '10px' }}>
                          {dish.descrizione}
                        </p>
                        <div className="product-price" style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '15px' }}>
                          {dish.prezzo} €
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          )}
        </Box>
      )}
    </Container>
  );
};

export default ShowMenu;
