import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

const CategoryDishes = ({ categoryId }) => {
    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(true);
    const general = useSelector((state) => state.general);

    useEffect(() => {
        const fetchDishes = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getDishesByCategory/${categoryId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );
                setDishes(response.data);
            } catch (error) {
                console.error("Error fetching dishes:", error);
            } finally {
                setLoading(false);
            }
        };

        if (categoryId) {
            fetchDishes();
        }
    }, [categoryId]);

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(dishes);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setDishes(reorderedItems);

        const reorderedIds = reorderedItems.map((dish) => dish.id);

        try {
            await axios.post(
                `${process.env.REACT_APP_APP_ENDPOINT}api/auth/updateDishesOrder`,
                {
                    categoryId,
                    reorderedIds,
                    userId: general.userId, // Assicurati che l'ID dell'utente venga inviato
                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            console.log("Order updated successfully");
        } catch (error) {
            console.error("Error updating order:", error);
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    Trascina i piatti per ordinarli nella categoria
                </Typography>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="dishes">
                            {(provided) => (
                                <TableContainer
                                    component={Paper}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Piatto</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dishes.map((dish, index) => (
                                                <Draggable
                                                    key={dish.id.toString()}
                                                    draggableId={dish.id.toString()}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <TableRow
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <TableCell>{dish.title}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </CardContent>
        </Card>
    );
};

export default CategoryDishes;
