import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";
import LogoutIcon from '@mui/icons-material/Logout'; // If you want to use an icon

import Badge from "@mui/material/Badge";
import logo from '../../logo_imorgante-removebg.png';

import { Link } from 'react-router-dom';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { customer, admin, agenzia, master } from "./sidebar";
import Button from "@mui/material/Button";
import { thunks } from "../../thunks/general";
import { useNavigate } from "react-router-dom";

import { CardActionArea } from "@mui/material";
import Breadcrumbs from "components/breadcrumbs";
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  IconButton,
  Stack,
  CardMedia,
  CardHeader,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItem,
  Divider,
  List,
  TextField,
  TableRow,
  TableCell,
  Container,
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SidebarMenu from './sidebar'; // Assicurati che il percorso sia corretto


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#ffffff",
  boxShadow: "none",
  width: "100%", // Aggiunto
  marginLeft: drawerWidth, // Aggiunto
  ...(open && {
    marginLeft: 0,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "fixed",

    height: "calc(100% - 0px)",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#003f57",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      width: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  },
}));

//const defaultTheme = createTheme();
const defaultTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.pac-container': {
          zIndex: 1400, // Imposta il valore di z-index che desideri
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Sovrascrive gli stili per gli input disabilitati
          '&.Mui-disabled:before': {
            // Rimuove il bordo inferiore per gli input disabilitati
            border: 0,
          },
        },
      },
    },
    // Personalizza tutti i componenti MUI TextField
    MuiTextField: {
      styleOverrides: {
        root: {

          marginTop: "0px",
          // Applica l'altezza massima ai componenti input all'interno dei TextField
          '& input': {
            maxHeight: '20px',
            padding: "5px",
            fontSize: "14px"
          },
          // Opzionalmente, puoi includere gli stili per le etichette (label) e gli elementi helperText
          '& .MuiInputLabel-root': {
            // Stili per le etichette
          },

        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        // Sovrascrivi lo stile per la loading overlay
        columnHeaders: {
          backgroundColor: '#004057', // Sfondo desiderato per le intestazioni
          color: "white",
          borderBottom: "1px solid black"
        },
        columnHeader: {
          backgroundColor: '#004057', // Sfondo desiderato per le intestazioni
          color: "white",
          borderRight: "1px dotted grey"
        },
        overlay: {
          backgroundColor: 'rgba(255,255,255,1)', // Modifica l'opacità (0.5 in questo caso) per rendere l'overlay più scuro
        },
      },
    },
    // Puoi aggiungere altre personalizzazioni per diversi componenti qui
  },
});

export const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(!isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const general = useSelector((state) => state.general);


  const handleLogout = () => {

    localStorage.removeItem('token');
    dispatch(thunks.resetState());
    navigate('/');
  };


  useEffect(() => {
    if (isMobile) {
      setOpen(false); // Chiude la sidebar su dispositivi mobili
    } else {
      setOpen(true); // Apre la sidebar su dispositivi più grandi
    }
  }, [isMobile]);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const ToolbarNew = () => {
    return (
      <Toolbar sx={{ boxShadow: "1px 1px 3px #c9c9c9" }}>



        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            {/*  <Logo /> */}
            <img src="https://kpsfactory.com/wp-content/uploads/2023/04/Logo-KPS-02.webp" style={{ width: "170px" }} />
          </Box>
        </Box>


      </Toolbar>
    );
  }



  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer variant="permanent" open={open}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ paddingRight: "20px", background: "white", borderRadius: "10px", padding: "10px", marginBottom: "10px" }}>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                {/*  <Logo /> */}
                <img src={logo} style={{ width: "100%" }} />
              </Box>
            </Box>
            <Typography color="white" variant="subtitle1"> {/* Adjusted variant */}
              {general.username}
            </Typography>
            <Typography
              color="white"
              variant="subtitle2" // Adjusted variant
              fontSize="11px"
              display="block"
              lineHeight="1.0"
            >
              Hai effettuato l'accesso come <b>{general.temporaryRole}</b>
            </Typography>
          </Box>
          <Divider />
          <List component="nav">
            <ListItemButton component={Link} to="/dashboard">
              <ListItemIcon>
                <DashboardIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ color: "white" }} />

            </ListItemButton>
            <SidebarMenu />
          </List>

        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: "#f3fbff",
            flexGrow: 1,
            paddingLeft: "5px",
            height: "100vh",
            overflow: "auto",
            transition: "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1)", // Aggiunto
            marginLeft: open ? `${drawerWidth}px` : "0",
          }}>
          {isMobile}
          <Breadcrumbs />
          {children}
        </Box>
      </Box >
    </ThemeProvider >
  );
};
