import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { CardActionArea } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import { Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import TokenIcon from '@mui/icons-material/CurrencyBitcoin'; // 
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import StarRateIcon from '@mui/icons-material/StarRate';
import EuroIcon from '@mui/icons-material/Euro';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FolderIcon from '@mui/icons-material/Folder';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const items = [
    { title: 'Crea Piatto', icon: <PersonAddIcon fontSize="large" />, path: '/list/account' },
    { title: 'Crea Categoria', icon: <ShoppingCartIcon fontSize="large" />, path: '/list/ordini' },

    { title: 'Vai al Menu Pubblico', icon: <FolderIcon fontSize="large" />, path: '/menu' },
];


function Dashboard() {
    const general = useSelector((state) => state.general);

    // Dati fittizi per la demo
    const data = {
        totalAuctions: 120,
        activeUsers: 350,
        tokensSold: 5000,
    };
    const dataInserzioni = [
        { name: 'Settimana 1', Approvate: 30, InAttesa: 20, Rifiutate: 5 },
        { name: 'Settimana 2', Approvate: 45, InAttesa: 25, Rifiutate: 10 },
        { name: 'Settimana 3', Approvate: 20, InAttesa: 18, Rifiutate: 8 },
        { name: 'Settimana 4', Approvate: 27, InAttesa: 15, Rifiutate: 12 },
    ];




    // Dati di esempio
    const dataAgenzieMasters = [
        { name: 'Agenzie', value: 400 },
        { name: 'Masters', value: 300 },
    ];

    const COLORS = ['#0088FE', '#FF8042'];


    const dataAgenzieToken = [
        { name: 'Agenzia 1', Token: 200 },
        { name: 'Agenzia 2', Token: 300 },
        { name: 'Agenzia 3', Token: 150 },
        // ... altri dati
    ];

    // Per mostrare il grafico



    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card sx={{ m: 1 }}>
                    <CardContent>
                        <Typography variant="h4" >
                            Benvenuto, {general.username}
                        </Typography>





                    </CardContent>
                </Card>
            </Grid>


            {items.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index} >
                    <Link to={item.path} style={{ textDecoration: 'none' }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 2,
                                m: 1,
                                backgroundColor: 'white',
                                height: '100%',
                                cursor: 'pointer',
                                borderRadius: "5px",
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <IconButton
                                sx={{
                                    color: '#003f57',
                                    fontSize: '2rem',
                                }}
                            >
                                {item.icon}
                            </IconButton>
                            <CardContent>
                                <Typography variant="h6" align="center" color="text.primary">
                                    {item.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
}

export default Dashboard;